import {
  addChannelPush,
  getChannnlPushDetail,
  listChannelPush,
  listCompanyInfo,
  updateChannelPush,
} from '@/apis/api';
import BoolStatus from '@/components/BoolStatus';
import ChannelSelect from '@/components/ChannelSelect';
import { CompanySelect } from '@/components/CompanySelect';
import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Tag,
  Tree,
  message,
} from 'antd';
import { DataNode } from 'antd/es/tree';
import dayjs from 'dayjs';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const context = createContext<{ refresh: () => void }>({ refresh() {} });

const buildDepartmentTree = (
  companyInfo: I.CompanyInfo,
  parentId?: I.ID,
): DataNode[] => {
  const departmentChildren = companyInfo.departments
    ?.filter((item) => {
      if (!parentId) {
        return !item.parentId;
      }
      return item.parentId === parentId;
    })
    .map((item) => {
      return {
        title: item?.name,
        value: item?.id,
        key: `d-${item?.id}`,
        children: buildDepartmentTree(companyInfo, item.id),
      };
    });
  const userChildren = companyInfo.users
    .filter((user) => {
      if (!parentId) {
        return !user.departmentId;
      }
      return user.departmentId === parentId;
    })
    .map((item) => {
      return {
        title: item?.name,
        value: item.id,
        key: `u-${item.id}`,
      };
    });
  const children = [...departmentChildren, ...userChildren];
  return children;
};

const TargetSelect = ({
  companyId,
  value,
  defaultValue,
  onChange,
}: { companyId: I.ID } & {
  value: I.ID[];
  onChange: (v: I.ID[]) => void;
  defaultValue: I.ID[];
}) => {
  const { data, loading } = useRequest(
    () => {
      return listCompanyInfo(companyId).then((res) => {
        return {
          ...res,
          users: res?.users?.filter((user) => user.state === 'ACTIVE'),
        };
      });
    },
    {
      refreshDeps: [companyId],
    },
  );

  const treeData = useMemo(() => {
    return data ? buildDepartmentTree(data) : [];
  }, [data]);

  return (
    <Spin spinning={loading}>
      <Tree
        treeData={treeData}
        checkable
        defaultExpandedKeys={defaultValue}
        checkedKeys={value}
        onCheck={(v) => {
          if (Array.isArray(v)) {
            onChange(v);
          }
        }}
      />
    </Spin>
  );
};

const AddOrUpdateChannelPushModal = ({
  refresh,
  type,
  id,
}: {
  refresh: () => void;
  type: 'add' | 'update';
  id?: I.ID;
}) => {
  const [open, setOpen] = useState<boolean>();

  const [form] = Form.useForm<{
    channel_id: I.ID;
    company_id: I.ID;
    remark?: string;
    limit?: number;
    mode: I.ChannelMode;
    status?: boolean;
  }>();

  const companyId = Form.useWatch('company_id', form);
  const mode = Form.useWatch('mode', form);

  const { data, refresh: refreshData } = useRequest(
    () => {
      return getChannnlPushDetail(id!).then((v) => ({
        channel_id: v.channel.id,
        company_id: v.company.id,
        remark: v.remark,
        limit: v.limit,
        mode: v.mode,
        status: v.status,
        targets: v.targets,
      }));
    },
    {
      ready: !!id && open,
      refreshDeps: [id],
    },
  );

  const [targets, setTargets] = useState<I.ID[]>([]);

  const { runAsync, loading: addLoading } = useRequest(addChannelPush, {
    manual: true,
    onSuccess: () => {
      setOpen(false);
      message.success('添加成功');
      refresh();
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { runAsync: update, loading: updateLoading } = useRequest(
    updateChannelPush,
    {
      manual: true,
      onSuccess: () => {
        setOpen(false);
        message.success('更新成功');
        refresh();
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const loading = updateLoading || addLoading;

  useEffect(() => {
    if (data?.mode === 'target' && data.company_id) {
      setTargets(data.targets.map((item) => `u-${item.target_id}`));
    }
  }, [data, setTargets]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      refreshData();
    }
  }, [open, form, refreshData]);

  const submitTarget = useMemo(() => {
    return targets
      .filter((item) => {
        if (typeof item === 'string') {
          return item.startsWith('u-');
        }
        return true;
      })
      .map((item) => {
        if (typeof item === 'string') {
          return parseInt(item.substring(2));
        }
        return item;
      });
  }, [targets]);

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        if (type === 'add' && !id) {
          return runAsync({
            ...values,
            targets: submitTarget,
          });
        }
        return update(id!, {
          ...values,
          targets: submitTarget,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const inSelectTargetMode = !!companyId && mode === 'target';

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        title={type === 'add' ? '新增推送' : '修改'}
        onOk={onSubmit}
        width={inSelectTargetMode ? 800 : 600}
      >
        <Row>
          <Col span={inSelectTargetMode ? '12' : '24'}>
            <Form
              form={form}
              initialValues={
                type === 'add'
                  ? {
                      mode: 'mean',
                      targets: [],
                    }
                  : { ...data }
              }
            >
              <Form.Item
                label="来源渠道"
                name="channel_id"
                rules={[{ required: true }]}
              >
                <ChannelSelect />
              </Form.Item>
              <Form.Item
                label="目标公司"
                name="company_id"
                rules={[{ required: true }]}
              >
                <CompanySelect />
              </Form.Item>
              <Form.Item
                label="推送模式"
                name="mode"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={'mean'}>均推</Radio>
                  <Radio value={'target'}>定推</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="推送上限" name="limit">
                <InputNumber precision={0} min={0} />
              </Form.Item>
              <Form.Item label="推送说明" name="remark">
                <Input.TextArea rows={3} />
              </Form.Item>
              <Form.Item label="推送状态" name="status" valuePropName="checked">
                <Checkbox>开启</Checkbox>
              </Form.Item>
            </Form>
          </Col>
          {inSelectTargetMode && (
            <Col span={12}>
              <TargetSelect
                defaultValue={data?.targets.map((item) => item.target_id) || []}
                companyId={companyId}
                value={targets}
                onChange={setTargets}
              />
            </Col>
          )}
        </Row>
      </Modal>
      <Button onClick={() => setOpen(true)} type="primary">
        {type === 'add' ? '新增推送' : '修改'}
      </Button>
    </>
  );
};

const UpdateButton = ({ id }: { id: I.ID }) => {
  const { refresh } = useContext(context);
  return (
    <AddOrUpdateChannelPushModal
      type="update"
      id={id}
      refresh={() => {
        refresh();
      }}
    />
  );
};

const columns: ProColumns<I.ChannelPushItem>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    search: false,
  },
  {
    title: '来源渠道',
    search: false,
    render: (_, { channel, source }) => {
      return (
        <>
          {channel?.name}-{source?.name}
        </>
      );
    },
  },
  {
    title: '推送公司',
    search: false,
    render: (_, { company, status }) => {
      return (
        <Space>
          <span>{company?.name}</span>
          <BoolStatus status={status} />
        </Space>
      );
    },
  },
  {
    title: '推送方式',
    search: false,
    render: (_, { limit, mode }) => {
      return (
        <Space>
          <Tag>{mode === 'mean' ? '均推' : '定推'}</Tag>
          <Tag>上限：{limit || '-'}</Tag>
        </Space>
      );
    },
  },
  {
    title: '创建人',
    search: false,
    render: (_, { user }) => {
      return <>{user.name}</>;
    },
  },
  {
    title: '推送数量',
    search: false,
    render: (_, { daily_count, count }) => {
      return (
        <>
          {daily_count}/{count}
        </>
      );
    },
  },
  {
    title: '创建时间',
    search: false,
    render: (_, { created_at }) => {
      return <>{dayjs(created_at).format('YYYY-MM-DD HH:mm')}</>;
    },
  },
  {
    title: '操作',
    search: false,
    render: (_, { id, channel }) => {
      if (channel.name.startsWith('RE-')) {
        return null;
      }
      return <UpdateButton id={id} />;
    },
  },
  {
    hideInTable: true,
    dataIndex: 'companyId',
    title: '目标公司',
    renderFormItem: () => {
      return <CompanySelect allowClear />;
    },
  },
  {
    hideInTable: true,
    dataIndex: 'channelId',
    title: '来源渠道',
    renderFormItem: () => {
      return <ChannelSelect allowClear />;
    },
  },
  {
    hideInTable: true,
    dataIndex: 'status',
    title: '推送状态',
    valueType: 'select',
    search: {
      transform: (v) => {
        let status: boolean | undefined;
        if (v === 'true') {
          status = true;
        } else if (v === 'false') {
          status = false;
        }
        return {
          status,
        };
      },
    },
    valueEnum(row) {
      return {
        true: {
          text: '开启',
          status: 'true',
        },
        false: {
          text: '关闭',
          status: 'false',
        },
      };
    },
  },
];

export default function ChannelPush() {
  const actionRef = useRef<ActionType>();
  return (
    <context.Provider value={{ refresh: () => actionRef.current?.reload() }}>
      <ProTable
        columns={columns}
        bordered
        actionRef={actionRef}
        size="small"
        toolBarRender={() => [
          <AddOrUpdateChannelPushModal
            key="add"
            type="add"
            refresh={() => actionRef.current?.reloadAndRest?.()}
          />,
        ]}
        request={async ({ pageSize, current, ...params }) => {
          return listChannelPush({
            page: current,
            size: pageSize,
            company_id: params.companyId && [params.companyId],
            channel_id: params.channelId && [params.channelId],
            status: params.status,
          }).then((res) => ({
            data: res.list,
            success: true,
            total: res.total,
          }));
        }}
        rowKey={'id'}
      />
    </context.Provider>
  );
}
